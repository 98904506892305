import React from "react"
import styled from "styled-components"
import { ProductArticle, ProductBreakdown } from "../../components"
import { ISharpGatsbyImageArgs, StaticImage } from "gatsby-plugin-image"
import theme from "../../theme"
import ProductCTA from "../../components/Products/ProductCTA"
import { FUNERAL_COLOR } from "."
import { PRODUCT_URLS } from "../../constants/urls"
import { graphql, useStaticQuery } from "gatsby"

const PATH_FUNERAL = "../../assets/images/insurance-basics/"

export const COMP_DETAILS = [
  {
    opacity: "0.6",
    benefits: [
      {
        label: "Affordable Funeral Insurance",
        text: "Funeral insurance should be accessible to everyone. Our funeral insurance products are designed to be affordable while providing comprehensive coverage for dignified burials.",
      },
    ],
  },
  {
    opacity: "0.3",
    benefits: [
      {
        label: "Funeral Insurance for Senior Citizens",
        text: "We understand the unique needs of senior citizens. Our funeral insurance options cater to senior citizens, ensuring that their funeral expenses are covered.",
      },
    ],
  },
  {
    opacity: "0.6",
    benefits: [
      {
        label: "Funeral Insurance for Dignified Burials",
        text: "A dignified farewell is a testament to a life well lived. Our funeral insurance products ensure that your loved ones can say their goodbyes with dignity, without the financial burden.",
      },
    ],
  },
]

const ImageProperties: {
  alt: string
  placeholder: ISharpGatsbyImageArgs["placeholder"]
  quality: number
} = {
  alt: "",
  placeholder: "blurred",
  quality: 100,
}

const ArticleProperties = {
  highlightTitle: false,
  backArrow: false,
  className: "articleContainer",
}

const query = graphql`
  {
    bgImage: file(
      relativePath: {
        eq: "products/comprehensive-legal-insurance/additional-benefits.png"
      }
    ) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const ProductDetailsFuneral: React.FC = () => {
  const funeralData = useStaticQuery(query)
  const funeralImageData = funeralData.bgImage.childImageSharp.fluid
  return (
    <Container textColor={FUNERAL_COLOR}>
      <ArticlesContainer>
        <ProductArticle
          {...ArticleProperties}
          color={theme.palette.custom.yellow}
          title="Funeral Insurance Products"
          image={
            <StaticImage
              src={`${PATH_FUNERAL}/article-3.png`}
              {...ImageProperties}
            />
          }
          showButton={false}
        >
          <p>
            Funeral expenses can place a significant financial burden on your
            loved ones. Workerslife&apos;s funeral insurance products offer
            peace of mind during difficult times.
          </p>
        </ProductArticle>
      </ArticlesContainer>
      <p>
        <strong>Group Funeral Benefit:</strong> Our Group Funeral Cover is
        designed for groups of between 10 – 200 principle members. It&apos;s
        customizable to cater to the specific needs of your group.
      </p>
      <ProductCTA
        description="Explore our Group Funeral Benefit, learn how this product can cater to
          the specific needs of your group, providing peace of mind during
          challenging times."
        color={FUNERAL_COLOR}
        label="Explore Group Funeral Insurance"
        link={PRODUCT_URLS.GROUP_FUNERAL_BENEFIT}
      />
      <p>
        <strong>Comprehensive Legal Insurance:</strong> Legal matters can be
        complex and costly. Our Comprehensive Legal Insurance solutions provide
        access to expert legal advice and support, ensuring that you&apos;re
        always prepared, whether it&apos;s for labour disputes, civil cases, or
        other legal matters.
      </p>
      <ProductCTA
        description="Delve into the details of our Comprehensive Funeral Benefit"
        color={FUNERAL_COLOR}
        label="Learn More About Comprehensive Funeral Cover"
        link={PRODUCT_URLS.COMPREHENSIVE_FUNERAL_BENEFIT}
      />
      <p>
        <strong>Platinum Plus Funeral Benefit:</strong> The Platinum Plus
        Funeral Benefit offers an enhanced funeral expense benefit for the main
        member, spouse, up to 6 children, and extended family members. It
        ensures that your family receives maximum benefits during a challenging
        time.
      </p>
      <ProductCTA
        description="Find out how our Platinum Plus Funeral Benefit offers enhanced funeral expense benefits."
        color={FUNERAL_COLOR}
        label="Explore Platinum Plus Funeral Cover"
        link={PRODUCT_URLS.PLATINUM_PLUS_FUNERAL_BENEFIT}
      />
      <p>
        <strong>POPCRU 25 Funeral Benefit:</strong> Available exclusively for
        POPCRU members, the POPCRU 25 Funeral Benefit offers enhanced funeral
        expense benefits for POPCRU members, their spouses, up to 7 children
        (max age 26), and extended family members.
      </p>
      <ProductCTA
        description="Discover the exclusive benefits of the POPCRU 25 Funeral Benefit, available only to POPCRU members."
        color={FUNERAL_COLOR}
        label="Learn More About POPCRU 25 Funeral Cover"
        link={PRODUCT_URLS.POPCRU_25_FUNERAL_BENEFIT}
      />
      <ProductBreakdown
        color={FUNERAL_COLOR}
        details={COMP_DETAILS}
        imageData={funeralImageData}
      />
    </Container>
  )
}

export default ProductDetailsFuneral

const Container = styled.div<{
  textColor: string
}>`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 100%;
  margin: 60px auto;
  padding: 0 20px;
  strong {
    font-weight: 600;
    color: ${props => props.textColor};
  }

  @media only screen and (min-width: ${props => props.theme.breakpoints.sm}) {
    margin: 90px auto;
    padding: 0 58px;
  }
`

const ArticlesContainer = styled.div`
  display: grid;
  .articleContainer {
    padding-right: 0;

    .article {
      gap: 80px;

      .imageContainer {
        height: 88%;
      }

      h1 {
        background-color: transparent;
        color: #000;
      }

      h1,
      p,
      .articleFooter {
        padding-left: 0;
        padding-right: 0;
      }

      @media screen and (max-width: ${props => props.theme.maxBreakpoints.md}) {
        h1 {
          margin: 0;
        }
      }
    }

    @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
      &:nth-child(even) .coverButton {
        justify-self: flex-start;
      }
    }
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.lg}) {
    gap: 140px;

    &:nth-child(odd) .article {
      grid-template-columns: 1fr minmax(500px, 520px);
    }

    &:nth-child(even) .article {
      grid-template-columns: minmax(500px, 520px) 1fr;
    }

    .article {
      h1 {
        margin-top: 0;
        max-width: 505px;
        padding-top: 0;
      }
    }
  }
`
