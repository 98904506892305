import React from "react"
import styled from "styled-components"
import { MENU_LINKS } from "../../constants/urls"
import { Menu } from "../../components"

const Buttons: React.FC = () => {
  return (
    <Container>
      {MENU_LINKS.map((productList, index) => (
        <Menu key={`offers-menu-${index}`} dropDown properties={productList} />
      ))}
    </Container>
  )
}

export default Buttons

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 100%;
  margin: 60px auto;
  padding: 0 20px;

  @media only screen and (min-width: ${props => props.theme.breakpoints.sm}) {
    margin: 90px auto;
    padding: 0 58px;
  }
`
