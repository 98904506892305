import theme from "../../theme"

export const PRODUCT_COLOR = theme.palette.custom.blue
export const LIFE_COLOR = theme.palette.custom.blue
export const FUNERAL_COLOR = theme.palette.custom.yellow
export const LEGAL_COLOR = theme.palette.custom.green

export { default as Introduction } from "./Introduction"
export { default as Buttons } from "./Buttons"
export { default as ProductDetailsLife } from "./ProductDetailsLife"
export { default as ProductDetailsShort } from "./ProductDetailsShort"
export { default as ProductDetailsFuneral } from "./ProductDetailsFuneral"
