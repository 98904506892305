import React from "react"
import styled from "styled-components"

const Introduction: React.FC = () => {
  return (
    <Container>
      <HeaderWrapper>
        <h1>
          Protecting Your Family&apos;s Future: Workerslife&apos;s Comprehensive
          Range of Insurance Products
        </h1>
        <p>
          We believe in securing your legacy beyond your lifetime. Explore our
          range of insurance solutions designed to safeguard your family&apos;s
          financial well-being.
        </p>
      </HeaderWrapper>
      <p>
        At Workerslife, we are committed to your family&apos;s financial
        security. We understand that planning for the future is more than just a
        necessity; it&apos;s a responsibility. That&apos;s why we offer a
        diverse range of insurance products to cater to your unique needs and
        secure your family&apos;s financial future.
      </p>
    </Container>
  )
}

export default Introduction

const Container = styled.div`
  margin: 30px auto;
  p {
    padding: 0 20px;
  }

  @media only screen and (min-width: ${props => props.theme.breakpoints.md}) {
    margin: 60px auto;
    p {
      padding: 0 58px;
    }
  }
`

const HeaderWrapper = styled.div`
  margin: 60px auto;
  padding: 0 20px;

  h1 {
    text-align: center;
    margin-bottom: 10px;
    font-size: 28px;
    line-height: 40px;
  }

  p {
    font-style: italic;
    font-weight: 300;
    text-align: center;
    margin-bottom: 40px;
  }

  .coverButton {
    margin: 0 auto;
  }

  @media only screen and (min-width: ${props => props.theme.breakpoints.sm}) {
    margin: 90px auto;
    h1 {
      font-size: 52px;
      line-height: 69px;
    }
    p {
      font-size: 26px;
      margin-bottom: 30px;
    }
  }

  @media only screen and (min-width: ${props => props.theme.breakpoints.md}) {
    .coverButton {
      margin: 80px auto 0px auto;
    }
  }

  @media only screen and (min-width: ${props => props.theme.breakpoints.lg}) {
  }
`
