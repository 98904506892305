import React from "react"
import styled from "styled-components"

import { SEO } from "../../components"
import { ArticleJsonLd } from "gatsby-plugin-next-seo"
import {
  Buttons,
  Introduction,
  ProductDetailsFuneral,
  ProductDetailsLife,
  ProductDetailsShort,
} from "../../sections/products"

const DESCRIPTION =
  "Discover comprehensive insurance solutions at Workerslife. Life, funeral, and short-term coverage to secure your family's future. Explore now."
const IMAGE =
  "https://www.workerslife.co.za/static/aff869badbff33274cad549af8285dac/aef0c/header.webp"
const Products: React.FC = () => {
  return (
    <PageWrapper>
      <SEO title="Insurance Products" description={DESCRIPTION} image={IMAGE} />
      <Introduction />
      <ArticleJsonLd
        authorName="Workerslife"
        authorType="Organization"
        headline="What we have to offer"
        description={DESCRIPTION}
        url="https://www.workerslife.co.za/products"
        images={[IMAGE]}
        publisherName=""
        publisherLogo=""
        datePublished=""
      />
      <Buttons />
      <ProductDetailsLife />
      <ProductDetailsFuneral />
      <ProductDetailsShort />
    </PageWrapper>
  )
}

export default Products

const PageWrapper = styled.div`
  @media screen and (max-width: ${props => props.theme.maxBreakpoints.md}) {
    padding-bottom: 40px;
  }
`
