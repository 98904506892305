import React from "react"
import styled from "styled-components"
import { ProductArticle, ProductBreakdown } from "../../components"
import { ISharpGatsbyImageArgs, StaticImage } from "gatsby-plugin-image"
import theme from "../../theme"

import { LIFE_COLOR } from "."
import { graphql, useStaticQuery } from "gatsby"
import ProductCTA from "../../components/Products/ProductCTA"
import { PRODUCT_URLS } from "../../constants/urls"

export const LIFE_DETAILS = [
  {
    opacity: "0.6",
    benefits: [
      {
        label: "Affordable Life Insurance",
        text: "We understand the importance of life insurance that doesn't break the bank. Our life insurance options are designed to be affordable while offering substantial cover for your family's peace of mind.",
      },
    ],
  },
  {
    opacity: "0.3",
    benefits: [
      {
        label: "Term Life Insurance",
        text: "Enjoy the convenience of saving with 24/7 grocery coupons available on the Legal App. This is a practical benefit that can help with daily expenses.",
      },
    ],
  },
  {
    opacity: "0.6",
    benefits: [
      {
        label: "Whole Life Insurance",
        text: "In challenging situations, our product provides bail and fine assistance to ensure you have the support you need.",
      },
    ],
  },
  {
    opacity: "0.3",
    benefits: [
      {
        label: "Family Life Insurance with Income Replacement",
        text: "In challenging situations, our product provides bail and fine assistance to ensure you have the support you need.",
      },
    ],
  },
]

const PATH_LIFE = "../../assets/images/insurance-basics/"

const ImageProperties: {
  alt: string
  placeholder: ISharpGatsbyImageArgs["placeholder"]
  quality: number
} = {
  alt: "",
  placeholder: "blurred",
  quality: 100,
}

const ArticleProperties = {
  highlightTitle: false,
  backArrow: false,
  className: "articleContainer",
}

const query = graphql`
  {
    bgImage: file(
      relativePath: {
        eq: "products/comprehensive-legal-insurance/additional-benefits.png"
      }
    ) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const ProductDetailsLife: React.FC = () => {
  const lifeData = useStaticQuery(query)
  const lifeImageData = lifeData.bgImage.childImageSharp.fluid

  return (
    <Container textColor={LIFE_COLOR}>
      <ArticlesContainer>
        <ProductArticle
          {...ArticleProperties}
          color={theme.palette.custom.blue}
          title="Life Insurance Products"
          image={
            <StaticImage
              src={`${PATH_LIFE}/article-2.png`}
              {...ImageProperties}
            />
          }
          imageLeft
          showButton={false}
        >
          <p>
            Life insurance is the cornerstone of financial planning. It ensures
            that your family&apos;s financial future remains secure even when
            you&apos;re not around. At Workerslife, we offer a comprehensive
            life insurance product:
          </p>
        </ProductArticle>
      </ArticlesContainer>
      <p>
        <strong>Life and Live Secure Benefit:</strong> Our Life and Live Secure
        Benefit is designed to ensure that your dependents are financially cared
        for in the event of your death. It provides coverage that secures up to
        80% of your monthly income, along with comprehensive funeral cover,
        helping you leave a legacy that transcends your life.
      </p>
      <ProductCTA
        color={LIFE_COLOR}
        description="Discover how our Life and Live Secure Benefit can ensure your
          dependents are financially cared for in your absence. With a benefit
          that secures up to 80% of your monthly income, in addition to
          comprehensive funeral cover, you can leave a legacy that transcends
          your life."
        label="Explore Life Insurance: Life and Live Secure Benefit"
        link={PRODUCT_URLS.LIFE_AND_LIVE_BENEFIT}
      />

      <ProductBreakdown
        color={LIFE_COLOR}
        details={LIFE_DETAILS}
        imageData={lifeImageData}
      />
    </Container>
  )
}

export default ProductDetailsLife

const Container = styled.div<{
  textColor: string
}>`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 100%;
  margin: 60px auto;
  padding: 0 20px;
  strong {
    font-weight: 600;
    color: ${props => props.textColor};
  }

  @media only screen and (min-width: ${props => props.theme.breakpoints.sm}) {
    margin: 90px auto;
    padding: 0 58px;
  }
`

const ArticlesContainer = styled.div`
  display: grid;

  .articleContainer {
    padding-right: 0;

    .article {
      gap: 80px;

      .imageContainer {
        height: 88%;
      }

      h1 {
        background-color: transparent;
        color: #000;
      }

      h1,
      p,
      .articleFooter {
        padding-left: 0;
        padding-right: 0;
      }

      @media screen and (max-width: ${props => props.theme.maxBreakpoints.md}) {
        h1 {
          margin: 0;
        }
      }
    }

    @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
      &:nth-child(even) .coverButton {
        justify-self: flex-start;
      }
    }
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.lg}) {
    gap: 140px;

    &:nth-child(odd) .article {
      grid-template-columns: 1fr minmax(500px, 520px);
    }

    &:nth-child(even) .article {
      grid-template-columns: minmax(500px, 520px) 1fr;
    }

    .article {
      h1 {
        margin-top: 0;
        max-width: 505px;
        padding-top: 0;
      }
    }
  }
`
