import React from "react"
import styled from "styled-components"
import { StyledLink } from "../../elements"

interface ProductCTAProps extends React.HTMLAttributes<HTMLDivElement> {
  description: string
  label: string
  color: string
  link: string
}

const ProductCTA: React.FC<ProductCTAProps> = ({
  description,
  label,
  color,
  link,
}) => {
  return (
    <Container>
      <CoverWrapper textColor={color}>
        <p className="italic">{description}</p>
        <StyledLink label={label} link={link} arrowColor={color} />
      </CoverWrapper>
    </Container>
  )
}

export default ProductCTA

const Container = styled.div`
  padding: 0 20px;
  .italic {
    font-style: italic;
    margin-top: 40px;
    margin-bottom: 40px;
    text-align: center;
  }
  @media only screen and (min-width: ${props => props.theme.breakpoints.sm}) {
    padding: 0 58px;
  }
`

const CoverWrapper = styled.div<{ textColor: string }>`
  justify-content: center;
  .styled-link-label {
    font-size: 18px;
    line-height: 32px;
    font-weight: 700;
    color: ${props => props.textColor};
  }
  a {
    margin: 60px auto;
    display: flex;
    justify-content: center;
  }
  @media only screen and (min-width: ${props => props.theme.breakpoints.sm}) {
    .styled-link-label {
      font-size: 28px;
      line-height: 38px;
    }
  }
`
