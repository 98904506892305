import React from "react"
import styled from "styled-components"
import { ProductArticle, ProductBreakdown } from "../../components"
import { ISharpGatsbyImageArgs, StaticImage } from "gatsby-plugin-image"
import theme from "../../theme"
import ProductCTA from "../../components/Products/ProductCTA"
import { LEGAL_COLOR } from "."
import { PRODUCT_URLS } from "../../constants/urls"
import { graphql, useStaticQuery } from "gatsby"

const PATH_LEGAL = "../../assets/images/insurance-basics/"

export const LEGAL_DETAILS = [
  {
    opacity: "0.6",
    benefits: [
      {
        label: "Best Short-Term Insurance",
        text: "When it comes to short-term insurance, you deserve the best. Our short-term insurance options offer maximum coverage to protect your assets and investments.",
      },
    ],
  },
  {
    opacity: "0.3",
    benefits: [
      {
        label: "Affordable Short-Term Insurance",
        text: "Short-term insurance shouldn't strain your budget. Our options are not only affordable but provide comprehensive coverage for your peace of mind.",
      },
    ],
  },
  {
    opacity: "0.6",
    benefits: [
      {
        label: "Short-Term Insurance in South Africa",
        text: "As a South African company, we understand the unique needs of South Africans. Our short-term insurance products are tailored to the local market.",
      },
    ],
  },
  {
    opacity: "0.3",
    benefits: [
      {
        label: "Customizable Short-Term Insurance",
        text: "We believe in flexibility. Customise your short-term insurance.",
      },
    ],
  },
]

const ImageProperties: {
  alt: string
  placeholder: ISharpGatsbyImageArgs["placeholder"]
  quality: number
} = {
  alt: "",
  placeholder: "blurred",
  quality: 100,
}

const ArticleProperties = {
  highlightTitle: false,
  backArrow: false,
  className: "articleContainer",
}

const query = graphql`
  {
    bgImage: file(
      relativePath: {
        eq: "products/comprehensive-legal-insurance/additional-benefits.png"
      }
    ) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const ProductDetailsShort: React.FC = () => {
  const legalData = useStaticQuery(query)
  const legalImageData = legalData.bgImage.childImageSharp.fluid
  return (
    <Container textColor={LEGAL_COLOR}>
      <ArticlesContainer>
        <ProductArticle
          {...ArticleProperties}
          color={theme.palette.custom.green}
          title="Short-term Insurance Products"
          image={
            <StaticImage
              src={`${PATH_LEGAL}/article-4.png`}
              {...ImageProperties}
            />
          }
          imageLeft
          showButton={false}
        >
          <p>
            Life is full of surprises, both pleasant and unexpected. Our
            Short-Term Insurance options are tailored to protect your assets and
            investments, providing you with peace of mind in an unpredictable
            world.
          </p>
        </ProductArticle>
      </ArticlesContainer>
      <p>
        <strong>Comprehensive Legal Insurance:</strong> Legal matters can be
        complex and costly. Our Comprehensive Legal Insurance solutions provide
        access to expert legal advice and support, ensuring that you&apos;re
        always prepared, whether it&apos;s for labour disputes, civil cases, or
        other legal matters.
      </p>
      <ProductCTA
        color={LEGAL_COLOR}
        description="Discover how our Life and Live Secure Benefit can ensure your
          dependents are financially cared for in your absence. With a benefit
          that secures up to 80% of your monthly income, in addition to
          comprehensive funeral cover, you can leave a legacy that transcends
          your life."
        label="Explore Life Insurance: Life and Live Secure Benefit"
        link={PRODUCT_URLS.COMPREHENSIVE_LEGAL_INSURANCE}
      />

      <ProductBreakdown
        color={LEGAL_COLOR}
        details={LEGAL_DETAILS}
        imageData={legalImageData}
      />
    </Container>
  )
}

export default ProductDetailsShort

const Container = styled.div<{
  textColor: string
}>`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 100%;
  margin: 60px auto;
  padding: 0 20px;
  strong {
    font-weight: 600;
    color: ${props => props.textColor};
  }

  @media only screen and (min-width: ${props => props.theme.breakpoints.sm}) {
    margin: 90px auto;
    padding: 0 58px;
  }
`

const ArticlesContainer = styled.div`
  display: grid;

  .articleContainer {
    padding-right: 0;

    .article {
      gap: 80px;

      .imageContainer {
        height: 88%;
      }

      h1 {
        background-color: transparent;
        color: #000;
      }

      h1,
      p,
      .articleFooter {
        padding-left: 0;
        padding-right: 0;
      }

      @media screen and (max-width: ${props => props.theme.maxBreakpoints.md}) {
        h1 {
          margin: 0;
        }
      }
    }

    @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
      &:nth-child(even) .coverButton {
        justify-self: flex-start;
      }
    }
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.lg}) {
    gap: 140px;

    &:nth-child(odd) .article {
      grid-template-columns: 1fr minmax(500px, 520px);
    }

    &:nth-child(even) .article {
      grid-template-columns: minmax(500px, 520px) 1fr;
    }

    .article {
      h1 {
        margin-top: 0;
        max-width: 505px;
        padding-top: 0;
      }
    }
  }
`
